.artists-container {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: left;
  max-width: 800px;
  margin-bottom: 2rem;
}

.artist-list-item:hover {
  color: #284a8b;
  font-weight: bold;
  cursor: pointer;
}

.artist-bio {
  max-width: 600px;
  margin: auto;
}
