.editions-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
}

.edition-body {
  margin-bottom: 2rem;
}
