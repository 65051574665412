@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');

/* Global Styles */
:root {
  --primary-color: #284a8b;
  --dark-color: #333333;
  --dark-lighter: #999999;
  --white-color: #ffffff;
  --light-color: #f1f1f1;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --accent-color: #3962db;
}

.App {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: var(--light-color);
  color: var(--dark-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--dark-color);
}

ul {
  list-style: none;
}

img {
  width: 100%;
  display: block;
}

.img-tiny {
  width: 200px;
}

/* Admin */

.btn.admin-view-item {
  background-color: white;
  margin: 0rem;
}

.btn.admin-view-item.selected {
  background-color: var(--primary-color);
  color: white;
  opacity: 1;
}

/* Utilities */
.container {
  min-height: calc(100vh - 300px);
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

/* Text Styles*/
.x-large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.lead-sm {
  font-size: 1.2rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-light {
  color: var(--light-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-success {
  color: var(--success-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-weight-light {
  font-weight: 200;
}

/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

.px {
  padding: 0 0.5rem;
}
.px-1 {
  padding: 0 1rem;
}
.px-2 {
  padding: 0 2rem;
}
.px-3 {
  padding: 0 3rem;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

/* Grid */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
}

.btn-block-full {
  display: block;
  width: 100%;
}

.btn-sm {
  padding: 0.3rem 1rem;
  margin: 0.2rem;
}

.badge {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */
input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form-container {
  background-color: var(--white-color);
  border-radius: 2rem;
  padding: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}
textarea {
  min-height: 150px;
}

input[type='submit'],
button {
  font: inherit;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

.navbar {
  border: none;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  border-bottom: 2px solid var(--light-color);
  /* box-shadow: 0px 1px 5px 0 var(--dark-lighter); */
  background-color: var(--white-color);
  /* opacity: 0.9; */
}

.navbar ul {
  display: flex;
  flex-wrap: wrap;
}

.navbar .nav-item {
  font-weight: lighter;
  color: var(--dark-lighter);
  margin: 0.1rem 0.45rem;
  position: relative;
  display: inline-block;
}

.nav-item::after {
  transition: width 300ms;
  display: block;
  position: absolute;
  content: ' ';
  width: 0%;
  background-color: var(--primary-color);
  height: 2px;
}

.nav-item:hover::after {
  width: 100%;
}

.navbar a:hover {
  color: var(--dark-primary);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.nav-item.nav-active {
  color: var(--primary-color);
  font-weight: 400;
}

.nav-item.nav-active:hover {
  color: var(--primary-color);
}

.rite-logo {
  width: 250px;
}

.nav-brand {
  border-radius: 0.5em;
  box-shadow: -3px -3px 3px 0 white, 3px 3px 3px 0 rgb(0, 0, 0, 0.3);
  padding: 2rem;
}

/* Footer */
.footer {
  height: 180px;
  background-color: var(--white-color);
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 2px solid var(--light-color);
  /* box-shadow: 0px -1px 5px 0 var(--dark-lighter); */
}

.social-media-link {
  color: var(--primary-color);
  font-size: 1.5rem;
  background-color: white;
  display: inline-block;
  width: 2rem;
  margin: 1rem 0.2rem 1rem 0.2rem;
}
.social-media-link:hover {
  color: var(--accent-color);
}

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 510px) {
  .nav-item {
    font-size: 0.9rem;
  }
  .navbar {
    flex-direction: row;
    flex-wrap: wrap;
    height: 150px;
  }
}
